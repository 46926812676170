import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { type Observable, of as ObservableOf, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FRIENDSTATUS } from '../../app.constants';
import Country from '../models/country.model';
import User from '../models/user.model';
import { USERS as UsersURLs } from '../../api.constants';
import { ConnectedService } from './connected.service';
import { StorageService } from './storage.service';
import Sha256 from '../../../assets/sha256';

@Injectable({ providedIn: 'root' })
export class UsersService extends ConnectedService {

	constructor(
		private readonly httpConn: HttpClient,
		private readonly storageService: StorageService
	) {
		super();
	}

	resolvedUser: User;

	private hasNewFriends = new Subject<number>();

	private static friendStatusTranslator(status: string): FRIENDSTATUS {
		switch (status) {
			case 'friend': return FRIENDSTATUS.FRIEND;
			case 'requested': return FRIENDSTATUS.REQUESTED;
			case 'blocked': return FRIENDSTATUS.BLOCKED;
			case 'notfriend': default: return FRIENDSTATUS.NOTFRIEND;
		}
	}

	private static initializeRawUser(res: { [key: string]: any; }): User {
		const user = new User(res.id).initialize(
			res.username,
			res.name,
			res.surname,
			res.birthdate,
			res.gender,
			res.country1,
			res.country2,
			res.avatar,
			null,
			res.bio
		);
		if (res.email1) {
			user.email1 = res.email1;
		}
		return user;
	}

	getNewFriendsSubscription() {
		return this.hasNewFriends.asObservable();
	}

	getUserInfo(userId: string): Observable<User> {
		const { endp, meth } = UsersURLs.USERINFO;
		const endpoint = this.makeEndpoint(endp, { userId });

		if (userId === this.storageService.localUser.id) {
			return ObservableOf(this.storageService.localUser);
		}

		return this.httpConn.request<any>(meth, endpoint).pipe(
			map(UsersService.initializeRawUser.bind(this)),
			catchError((e) => this.handleError(e, null))
		);
	}

	getMyInfo(): Observable<User> {
		const { endp, meth } = UsersURLs.GETMYINFO;
		const endpoint = this.makeEndpoint(endp);

		return this.httpConn.request<any>(meth, endpoint).pipe(
			map(details => UsersService.initializeRawUser(details)),
			tap(me => this.storageService.localUser = me),
			catchError((e) => this.handleError(e, null))
		);
	}

	getAllFriendsList(showExtended = true): Observable<User[]> {
		const { endp, meth } = UsersURLs.GETALLFRIENDS;
		const endpoint = this.makeEndpoint(endp);

		return this.httpConn.request<any[]>(meth, endpoint, { observe: 'response' }).pipe(
			map((res) => res.status === 204
				? []
				: res.body.map((item) => {
					const user = new User(item.id).initialize(
						item.username, item.name, item.surname, item.birthdate, item.gender, item.country1, item.country2, item.avatar
					);
					user.friendStatus = UsersService.friendStatusTranslator(item.status);
					if (item.allcountries) {
						user.countriesList = item.allcountries.split(',')
							.map((country: string) => new Country(country));
					}

					return user;
				})
				.filter(item => showExtended || item.friendStatus === FRIENDSTATUS.FRIEND)
			),
			catchError((e) => this.handleError(e, []))
		);
	}

	setUserAvatar(base64avatar: string): Observable<string> {
		const { endp, meth } = UsersURLs.SETAVATAR;
		const endpoint = this.makeEndpoint(endp);
		const body = { avatar: base64avatar || null };

		return this.httpConn.request(meth, endpoint, { body, responseType: 'text', observe: 'response' }).pipe(
			map((res) => res.status === 204 ? null : res.body.replace(/"/g, '')),
			tap((res) => this.storageService.setLocalUserAvatar(res))
		);
	}

	editUserDetails(userDetails: { [key: string]: any; }): Observable<User> {
		const { endp, meth } = UsersURLs.EDITUSERINFO;
		const endpoint = this.makeEndpoint(endp);
		const body = userDetails;

		return this.httpConn.request(meth, endpoint, { body, observe: 'response' }).pipe(
			map(res => res.status === 204 ? this.storageService.localUser : UsersService.initializeRawUser(res.body)),
			tap((user: User) => this.storageService.localUser = user),
			catchError(e => this.handleError(e, null))
		);
	}

	editEmail(newEmail: string, hashedpass: string): Observable<string> {
		const { endp, meth } = UsersURLs.EDITUSEREMAIL;
		const endpoint = this.makeEndpoint(endp);
		const body = { email1: newEmail, hashedpass };

		return this.httpConn.request(meth, endpoint, { body, observe: 'response' }).pipe(
			map((res) => null),
			tap(() => this.storageService.localUser.email1 = newEmail),
			catchError((err: HttpErrorResponse) => {
				let response: string;
				switch (err.status) {
					case 403:
						response = 'Wrong password';
						break;
					case 409:
						response = 'This email is already in use by some other user!';
						break;
					default:
						response = 'Some error happened! Please verify email, password and connection and try again.';
						break;
				}
				return this.handleError<string>(err, response);
			})
		);
	}

	checkUser(username: string): Observable<boolean> {
		const { endp, meth } = UsersURLs.CHECKUSER;
		const endpoint = this.makeEndpoint(endp);
		const params = this.makeParams({ username });

		return this.httpConn.request(meth, endpoint, { params }).pipe(
			map((res) => true),
			catchError((e) => this.handleError(e, false))
		);
	}

	searchUser(username: string): Observable<User[]> {
		const { endp, meth } = UsersURLs.SEARCHUSER;
		const endpoint = this.makeEndpoint(endp);
		const params = this.makeParams({ username });

		return this.httpConn.request<any[]>(meth, endpoint, { params, observe: 'response' }).pipe(
			map((res) => res.status === 204
				? []
				: res.body.map((item) => new User(item.id).initialize(
					item.username, item.name, item.surname, item.birthdate, item.gender, item.country1, item.country2, item.avatar,
					UsersService.friendStatusTranslator(item.status)
				))
			)
		);
	}

	registerUser(email: string, plainpass: string, userToCreate: User): Observable<boolean> {
		const { endp, meth } = UsersURLs.REGISTER;
		const endpoint = this.makeEndpoint(endp);
		const password = Sha256.hash(plainpass);
		const body = {
			username: userToCreate.username,
			password,
			name: userToCreate.name,
			surname: userToCreate.surname,
			country1: userToCreate.country1.id,
			country2: userToCreate.country2?.id ?? '',
			email1: email,
			gender: userToCreate.gender,
			birthdate: userToCreate.birth ? userToCreate.birthStr : ''
		};

		return this.httpConn.request<any>(meth, endpoint, { body, observe: 'response' }).pipe(
			map((res) => res.status === 201), // if 200, no activation is required
			catchError((e) => this.handleError(e, true, true))
		);
	}

	verifyUser(type: string, fullcode: string) {
		const { endp, meth } = UsersURLs.VERIFY;
		const endpoint = this.makeEndpoint(endp);
		const body = { type, fullcode };

		return this.httpConn.request<void>(meth, endpoint, { body});
	}

	recoverGenerateCode(email: string): Observable<boolean> {
		const { endp, meth } = UsersURLs.RECOVERYGENERATE;
		const endpoint = this.makeEndpoint(endp);
		const body = { email };

		return this.httpConn.request(meth, endpoint, { body, observe: 'response' }).pipe(
			// map(res => res.status === 204),
			catchError((e) => this.handleError(e, null))
		);
	}

	recoverSetNewPass(pass: string, fullcode: string): Observable<unknown> {
		const { endp, meth } = UsersURLs.RECOVERYSAVE;
		const endpoint = this.makeEndpoint(endp);
		const hashedpass = Sha256.hash(pass);
		const body = { pass: hashedpass, fullcode };

		return this.httpConn.request(meth, endpoint, { body });
	}

	updateFriendStatus(user: User, action: number): Observable<number> {
		const { endp, meth } = UsersURLs.UPDATEFRIEND;
		const endpoint = this.makeEndpoint(endp, { userId: user.id });
		const body = { action };

		return this.httpConn.request<number>(meth, endpoint, { body });
	}

	getFriendRequests(): Observable<User[]> {
		const { endp, meth } = UsersURLs.GETFRIENDREQUESTS;
		const endpoint = this.makeEndpoint(endp);

		return this.httpConn.request<any[]>(meth, endpoint, { observe: 'response' }).pipe(
			map((res) => res.status === 204 ? [] : res.body.map(item => new User(item.id).initialize(
				item.username, item.name, item.surname, item.birthdate, item.gender, item.country1, item.country2, item.avatar,
				UsersService.friendStatusTranslator(item.status)
			))),
			tap(requests => { this.hasNewFriends.next(requests.length); }),
			catchError((e) => this.handleError(e, [] as User[]))
		);
	}
}
