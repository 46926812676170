@if (userFlags$ | async; as userFlags) {
	<div class="flags-container user-countries-container has-action-button collection"
		*ngIf="selectedView === 8">
		<dflgr-user-country-list [list]="userFlags" [input]="input"/>
	</div>
	<div class="flags-container world-map-container"
		*ngIf="selectedView === 9">
		<dflgr-world-map [list]="userFlags" [input]="input" (loadError)="errorLoadingMap()"/>
	</div>
} @else {
	<div class="spinner-container"><mat-spinner/></div>
}

<dflgr-handler-action-button
	[icon]="actionButtonIcon" label="Toggle between List and Map views" [action]="actionButtonAction"/>
