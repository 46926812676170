import { Component, HostBinding, Input } from '@angular/core';
import type { IFlagUser } from '../../services/flags.interfaces';
import User from '../../models/user.model';
import Group from '../../models/group.model';
import { UserCountryComponent } from '../user-country/user-country.component';
import { NgFor } from '@angular/common';

@Component({
	selector: 'dflgr-user-country-list',
	standalone: true,
	imports: [NgFor, UserCountryComponent],
	templateUrl: './user-country-list.component.html',
	styleUrls: ['./user-country-list.component.scss']
})
export class UserCountryListComponent {

	@HostBinding('class.user-countries-container') readonly classUserCountriesContainer = true;

	userFlags: IFlagUser[];

	@Input({ required: true }) set list(val: IFlagUser[]) {
		this.userFlags = val.filter((userFlag: IFlagUser) =>  !userFlag.items.every(item => item.isSelfCountry));
	}

	@Input() input: Group | User;

	get isGroup(): boolean {
		return this.input instanceof Group; // this.userFlags.some((userFlag) => userFlag.users.length > 1);
	}

}
