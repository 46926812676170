import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'dflgr-subscribable-base',
	standalone: true,
	template: ''
})
export class SubscribableBaseComponent implements OnDestroy {
	protected readonly ngUnsubscribe = new Subject<void>();

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
