import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safeImage',
	standalone: true
})
export class SafeImagePipe implements PipeTransform {
	constructor(private readonly sanitizer: DomSanitizer) {}

	transform(unsafeImg: string): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImg);
	}

}
