<mat-card class="user-card mat-elevation-z4">
	<mat-card-title-group class="card-big-title">
		<mat-card-title aria-label="User full name" tabindex="0" class="card-big-title-main">
			<span>@if (user?.hasName) { {{user?.name}} {{user?.surname}} } @else { {{user?.username}} }</span>
		</mat-card-title>
		<mat-card-subtitle *ngIf="user?.hasName" aria-label="Username" tabindex="0" class="card-big-title-sub">
			{{user?.username}}
			<!-- span class="gender">{{user?.genderIcon}}</span -->
		</mat-card-subtitle>
		<div class="avatar-container">
			<img mat-card-md-image [ngStyle]="defaultAvatarBgStyle" [alt]="user?.username" class="avatar" [src]="user?.avatarSrc">
		</div>
	</mat-card-title-group>
	<mat-card-content class="card-big-content">
		<a [routerLink]="user?.country1.link">
			<dflgr-country-flag [country]="user?.country1"/><br/>
		</a>
		<a *ngIf="user?.country2" [routerLink]="user?.country2.link">
			<dflgr-country-flag [country]="user?.country2"/>
		</a>
		<p *ngIf="user?.bio">{{user.bio}}</p>
	</mat-card-content>
	<mat-card-actions *ngIf="isSelf">
		<a mat-button color="primary" role="button" [routerLink]="editRoute"><mat-icon>edit</mat-icon> Edit details</a>
	</mat-card-actions>
</mat-card>
