import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { inOutItems } from '../../app.animations';
import User from '../../models/user.model';
import Group from '../../models/group.model';
import { UserCardComponent } from '../user-card/user-card.component';

@Component({
	animations: [ inOutItems ],
	selector: 'dflgr-user-card-list',
	standalone: true,
	imports: [UserCardComponent],
	templateUrl: './user-card-list.component.html',
	styleUrls: ['./user-card-list.component.scss']
})
export class UserCardListComponent {

	@HostBinding('class.collection') readonly classCollection = true;
	@Input({ required: true }) users: User[];
	@Input() group: Group;
	@Input() showFriendActions = false;
	@Output() readonly requestDeleteFromGroup = new EventEmitter<User>();
	@Output() readonly requestMakeGroupAdmin = new EventEmitter<User>();
	@Output() readonly userStatusChanged = new EventEmitter<User>();

}
