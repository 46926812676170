<h3 matDialogTitle>Crop your avatar</h3>
<mat-dialog-content>
	<p class="infotext" *ngIf="isAvatar">Flag Events might not display your new avatar until you log in again</p>
	<dflgr-cropper #cropperContainer
		[cropperOptions]="config"
		[imageUrl]="image"/>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onAcceptCrop()">Crop</button>
	<button mat-button color="secondary" [matDialogClose]="null">Cancel</button>
</mat-dialog-actions>
