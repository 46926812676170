import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import type { ISimplePromptData } from './simple-prompt-modal.interfaces';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';

@Component({
	selector: 'dflgr-simple-prompt-modal',
	standalone: true,
	imports: [MatDialogModule, NgFor, MatButtonModule],
	templateUrl: './simple-prompt-modal.component.html',
	styleUrls: ['./simple-prompt-modal.component.scss']
})
export class SimplePromptModalComponent<T> {

	constructor(
		@Inject(MAT_DIALOG_DATA) public readonly dialogData: ISimplePromptData<T>
	) {}
}
