@for (userFlag of userFlags; track userFlag.country) {
	<dflgr-user-country
		class="user-countries-container"
		[userFlag]="userFlag"
		[isGroup]="isGroup"
	/>
} @empty {
	<div class="empty-container">
		No flags here! It's time to start getting flags!
	</div>
}
