<mat-toolbar color="primary" class="header-app" role="toolbar">
	@if (header?.backButton) {
		<button mat-icon-button aria-label="Back" class="header-icon backicon" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
	} @else {
		<button mat-icon-button aria-label="Home" class="header-icon mainlogo" routerLink="/"></button>
	}
	<h1 [class.hide-if-mobile]="header?.text || header?.country" role="heading" tabindex="-1" aria-level="1" routerLink="/">{{title}}</h1>
	<h2 *ngIf="header?.text || header?.country" role="heading" tabindex="-1" [attr.aria-label]="header?.text" aria-level="2">
		@if (header.country) {
			<dflgr-country-flag [country]="header.country"/>
		} @else {
			{{header.text}}
		}
	</h2>
	<span class="h-spacer" role="separator"></span>
	<dflgr-extended-menu *ngIf="showLogged && (header?.actions || header?.filters || header?.sorters)" [options]="header.actions" [filters]="header.filters" [sorters]="header.sorters"/>
	<div class="action-unlogged" role="group" *ngIf="!showLogged">
		<button mat-button (click)="requestOpenLogin()"><span>Login</span></button>
		<!-- button mat-button *ngIf="notInReg" routerLink="/register"><span>Register</span></button -->
	</div>
</mat-toolbar>
