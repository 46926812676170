import { GENERAL, PATHS } from '../../app.constants';
import Country from './country.model';
import type User from './user.model';
import Utils from '../app.utils';

export enum VERB {FIRST = 1, REST}

export default class FlagEvent {
	id: string;
	user: User;
	eventDate: Date;
	country1: Country;
	country2: Country;
	text: string;
	img: string;
	accumulated1: number;
	accumulated2: number;
	likes?: string[];

	constructor(
		eventId1: string,
		country1Id: string,
		country2Id: string,
		user: User,
		text: string,
		img: any,
		when: Date,
		accumulated1: number,
		accumulated2?: number,
		likes?: string | string[]
	) {
		if (eventId1) {
			this.id = eventId1.toString();
		}
		this.country1 = new Country(country1Id);
		if (country2Id) {
			this.country2 = new Country(country2Id);
		}
		this.user = user;
		this.text = text;
		this.img = img;
		this.eventDate = when;
		this.accumulated1 = accumulated1;
		if (accumulated2) {
			this.accumulated2 = accumulated2;
		}
		this.likes = likes
			? Array.isArray(likes)
				? likes
				: likes.length
					? likes.split(',')
					: [likes.toString()]
			: [];
	}

	get isDouble(): boolean {
		return !!this.country2;
	}

	get whenLinkStr(): string {
		const formattedWhen = Utils.getUrlDate(this.eventDate);
		// MOMENT: this.eventDate.format(FLAGEVENTS.dateTimeRetrieve.fromUrl);
		return `${formattedWhen}${this.user.id}`;
	}

	set country1FromId(countryId: string) {
		this.country1 = new Country(countryId);
	}

	set country2FromId(countryId: string) {
		this.country2 = new Country(countryId);
	}

	/**
	 * Self country when all of event's flags are same as user's flags
	 */
	get isSelfCountry(): boolean {
		return ((this.country1.id === this.user.country1.id || this.country1.id === this.user.country2?.id)
			&& (!this.isDouble
				|| (this.country2.id === this.user.country1.id || this.country2.id === this.user.country2?.id)));
	}

	/**
	 * Array of strings to be used as input for Router.navigate()
	 */
	get whenlink(): string[] {
		return [`/${GENERAL.handlers.event.path}`, this.whenLinkStr];
	}

	get plainWhenlink(): string {
		return this.whenlink.join('/');
	}

	get imgSrc(): string {
		return this.img ? PATHS.eventPics + this.img : null;
	}

	get thumbSrc(): string {
		return this.img ? PATHS.eventPics + this.img.replace('.jpg', '_thumb.jpg') : null;
	}

	get verbCodes(): { verb1: VERB, verb2: VERB } {
		const acc1 = this.accumulated1;
		const allFlagEvents: any = {
			verb1: acc1 === 1 ? VERB.FIRST : VERB.REST,
			verb2: null
		};

		if (this.isDouble) {
			const acc2 = this.accumulated2;
			if (acc1 > 1 && acc2 === 1) {
				allFlagEvents.verb2 = VERB.FIRST;
			} else if (acc1 === 1 && acc2 > 1) {
				allFlagEvents.verb2 = VERB.REST;
			}
		}

		return allFlagEvents;
	}
}
