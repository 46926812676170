import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import Group from '../../models/group.model';
import User from '../../models/user.model';
import { GENERAL } from '../../../app.constants';
import { WorldCountryListComponent } from '../../components/world-country-list/world-country-list.component';
import { HeaderHandlerComponent } from '../../components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-flags-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, WorldCountryListComponent],
	templateUrl: './flags-handler.component.html',
	styleUrls: ['./flags-handler.component.scss']
})
export class FlagsHandlerComponent implements OnInit {
	@HostBinding('class.handler') readonly handlerClass = true;
	titleHolder: string;
	input: User | Group;
	/**
	 * Setting `user` excludes from setting `group` as well. Use one or another.
	 */
	@Input() set user(user: User) { // from route resolver
		if (user) {
			this.input = user;
		}
	}
	/**
	 * Setting `group` excludes from setting `user` as well. Use one or another.
	 */
	@Input() set group(group: Group) { // from route resolver
		if (group) {
			this.input = group;
		}
	}

	constructor(
		private readonly authProvider: AuthService,
	) { }

	ngOnInit(): void {
		if (this.input instanceof Group) {
			this.titleHolder = `${GENERAL.handlers.groups.flags.name}: ${this.input.name}`;
		} else {
			const isSelf = this.input.id === this.authProvider.localUser.id;
			this.titleHolder = isSelf ? 'My Flags' : `${this.input.username || this.input.name}: Flags`;
		}
	}

}
