import { Injectable } from '@angular/core';
import User from '../models/user.model';
import Country from '../models/country.model';
import { Subject } from 'rxjs';

const STRID = {
	COUNTRIES: 'dflgrcntrs',
	SESSION: 'dflgrsid',
	SELFUSER: 'dflgruid',
	ACCEPTED: 'dflgraccept',
	LANG: 'dflgrlng'
};

@Injectable({ providedIn: 'root' })
export class StorageService {

	private localStorage: Storage;
	private _localUser: User;
	private _localSession: string;
	private _localLanguage: string;
	private _localAccepted: '1';
	private _countries: Country[];

	userChanged = new Subject<User>();

	constructor() {
		this.localStorage = window.localStorage;
	}

	private getLocalItem(key: string): any {
		return this.localStorage.getItem(key);
	}

	private setLocalItem(key: string, value: string): void {
		this.localStorage.setItem(key, value);
	}

	private clearItem(key: string): void {
		if (key) {
			this.localStorage.removeItem(key);
		} else {
			this.localStorage.clear();
		}
	}

	get countries(): Country[] {
		if (!this._countries) {
			const countryList: { id: string; }[] = JSON.parse(this.getLocalItem(STRID.COUNTRIES));
			this._countries = countryList?.map(item => new Country(item.id));
		}
		return this._countries;
	}

	set countries(countriesList: Country[]) {
		this._countries = countriesList;
		this.setLocalItem(STRID.COUNTRIES, JSON.stringify(countriesList));
	}

	private clearLocalSession(): void {
		this._localSession = null;
		this.clearItem(STRID.SESSION);
	}

	set localSession(sessid: string) {
		this._localSession = sessid;
		this.setLocalItem(STRID.SESSION, sessid);
	}

	get localSession(): string {
		if (!this._localSession) {
			this._localSession = this.getLocalItem(STRID.SESSION);
		}
		return this._localSession;
	}

	private clearLocalUser(): void {
		this._localUser = null;
		this.clearItem(STRID.SELFUSER);
		this.userChanged.next(null);
	}

	get localUser(): User {
		if (!this._localUser) {
			const user: User = JSON.parse(this.getLocalItem(STRID.SELFUSER));
			this._localUser = user ? new User(user.id).initialize(
				user.username, user.name, user.surname, user.birth, user.gender,
				user.country1.id, user.country2?.id, user.avatar || null,
				null, user.bio
			) : null;
		}
		return this._localUser;
	}

	set localUser(user: User) {
		this._localUser = user;
		this.setLocalItem(STRID.SELFUSER, JSON.stringify(user));
		this.userChanged.next(this._localUser);
	}

	setLocalUserAvatar(avatarPath: string): void {
		const user = this.localUser;
		user.avatar = avatarPath;
		this.localUser = user;
	}

	private clearLocalLanguage(): void {
		this._localLanguage = null;
		this.clearItem(STRID.LANG);
	}

	set localLanguage(lang: string) {
		this._localLanguage = lang;
		this.setLocalItem(STRID.LANG, lang);
	}

	get localLanguage(): string {
		if (!this._localLanguage) {
			this._localLanguage = this.getLocalItem(STRID.LANG) || 'en';
		}
		return this._localLanguage;
	}

	get hasAccepted(): boolean {
		if (!this._localAccepted) {
			this._localAccepted = this.getLocalItem(STRID.ACCEPTED) || null;
		}
		return !!this._localAccepted;
	}

	set hasAccepted(accepted: boolean) {
		this._localAccepted = !!accepted ? '1' : null;
		this.setLocalItem(STRID.ACCEPTED, this._localAccepted);
	}

	storeSession(sessid: string, user: User): void {
		this.localSession = sessid;
		this.localUser = user;
	}

	endSession(): void {
		this.clearLocalSession();
		this.clearLocalUser();
	}
}
