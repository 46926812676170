import { Pipe, PipeTransform } from '@angular/core';
import { FLAGEVENTS } from '../../app.constants';

const MAX = FLAGEVENTS.hotnessColorMax;
const addPadding = (str: string, totalPos = 2, symbol = '0') =>
	str.length >= totalPos
		? str
		: new Array(totalPos - str.length + 1).join(symbol) + str;

@Pipe({
	name: 'hotnessColor',
	standalone: true
})
export class HotnessColorPipe implements PipeTransform {

	transform(input: number, max = MAX): string {
		if (!input || input === 0) {
			return '#ffffff';
		}

		input = input > max ? max : input;

		const rd = 'ff';
		let gr = '', bl = '';

		const partial = 256 - Math.floor(input * 255 / max);

		if (input <= (max / 2)) {
			gr = 'ff';
			bl = addPadding(partial.toString(16));
		} else /* if (input > (max / 2)) */ {
			bl = '00';
			gr = addPadding(partial.toString(16));
		}
		return `#${rd}${gr}${bl}`;
	}
}
