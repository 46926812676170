import { Component, Input, HostBinding } from '@angular/core';
import Country from '../../models/country.model';
import User from '../../models/user.model';
import { HotnessColorPipe } from '../../pipes/hotness-color.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf, NgFor } from '@angular/common';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
	selector: 'dflgr-user-country',
	standalone: true,
	imports: [MatCardModule, RouterLink, CountryFlagComponent, NgIf, MatExpansionModule, NgFor, HotnessColorPipe],
	templateUrl: './user-country.component.html',
	styleUrls: ['./user-country.component.scss']
})
export class UserCountryComponent {

	@HostBinding('class.user-countries') readonly classUserCountries = true;
	@HostBinding('class.card') readonly classCard = true;
	@HostBinding('class.item') readonly classItem = true;
	@Input({ required: true }) userFlag: {country: Country, total: number, items: any[]};
	@Input() isGroup = false;

	getUserCountryLink(user: User, country: Country) {
		return user?.getUserCountryLink(country) ?? [];
	}
}
