@for (user of users; track user.id) {
<dflgr-user-card
	[user]="user"
	[group]="group"
	[@inOutItems]
	(statusChanged)="userStatusChanged.emit(user)"
	(removeFromGroup)="requestDeleteFromGroup.emit(user)"
	(makeAdmin)="requestMakeGroupAdmin.emit(user)"
	[showFriendActions]="showFriendActions"
	class="item"/>
}
