import { Component, Input, HostBinding } from '@angular/core';
import { activeNavLinks } from './nav-icon.animations';
import { NgIf } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
	animations: [activeNavLinks],
	selector: 'dflgr-nav-icon',
	standalone: true,
	imports: [RouterLinkActive, RouterLink, MatIconModule, MatBadgeModule, NgIf],
	templateUrl: './nav-icon.component.html',
	styleUrls: ['./nav-icon.component.scss']
})
export class NavIconComponent {
	@HostBinding('attr.role') readonly role = 'link';
	@Input({ required: true }) icon: string;
	@Input() text: string;
	@Input() path: string | string[];
	@Input() badge ? = 0;
	@Input() showTitle = true;

	get titleText() {
		return this.text || '';
	}
}
