'use strict';

// decimal offset between ASCII capitals and regional indicator symbols
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flag = flag;
function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}
var OFFSET = 127397;

// module exposes a single function
function flag(country_code) {
  // only allow string input
  if (typeof country_code !== 'string') throw new TypeError('argument must be a string');
  // ensure country code is all caps
  var cc = country_code.toUpperCase();
  // return the emoji flag corresponding to country_code or null
  return /^[A-Z]{2}$/.test(cc) ? String.fromCodePoint.apply(String, _toConsumableArray([].concat(_toConsumableArray(cc)).map(function (c) {
    return c.charCodeAt() + OFFSET;
  }))) : null;
}