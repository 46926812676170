import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material/material.module';

import { SubscribableBaseComponent } from './components/subscribable-base/subscribable-base.component';
import { FlagEventComponent } from './components/flag-event/flag-event.component';
import { FlagEventListFilteredComponent } from './components/flag-event-list-filtered/flag-event-list-filtered.component';
import { HeaderHandlerComponent } from './components/header-handler/header-handler.component';
import { UserCardListComponent } from './components/user-card-list/user-card-list.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { HeaderAppComponent } from './components/header-app/header-app.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UserCardBigComponent } from './components/user-card-big/user-card-big.component';
import { UserCountryComponent } from './components/user-country/user-country.component';
import { UserCountryListComponent } from './components/user-country-list/user-country-list.component';
import { FriendActionsComponent } from './components/friend-actions/friend-actions.component';
import { WorldMapComponent } from './components/world-map/world-map.component';
import { WorldCountryListComponent } from './components/world-country-list/world-country-list.component';
import { NavIconComponent } from './components/nav-icon/nav-icon.component';
import { HandlerActionButtonComponent } from './components/handler-action-button/handler-action-button.component';
import { ExtendedMenuComponent } from './components/extended-menu/extended-menu.component';
import { StaticFooterComponent } from './components/static-footer/static-footer.component';
import { CookiesAlertComponent } from './components/cookies-alert/cookies-alert.component';
import { CropperComponent } from './components/cropper/cropper.component';
import { FilterableEventsHandlerBaseComponent } from './handlers/filterable-events-handler-base/filterable-events-handler-base.component';

import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { SimplePromptModalComponent } from './components/simple-prompt-modal/simple-prompt-modal.component';
import { CropperModalComponent } from './components/cropper-modal/cropper-modal.component';

import { HotnessColorPipe } from './pipes/hotness-color.pipe';
import { ViewableActionsPipe } from './pipes/viewable-actions.pipe';
import { SortUsersPipe } from './pipes/sort-users.pipe';

import { InfiniteScrollerDirective } from './directives/infinite-scroller.directive';
import { CheckSamePassDirective } from './directives/check-same-pass.directive';
import { CountriesMapModule } from 'countries-map';

import { FrontPageComponent } from './handlers/front-page/front-page.component';
import { RankingHandlerComponent } from './handlers/ranking-handler/ranking-handler.component';
import { WallHandlerComponent } from './handlers/wall-handler/wall-handler.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FlagsHandlerComponent } from './handlers/flags-handler/flags-handler.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		MaterialModule,
		HttpClientModule,
		CountriesMapModule,
		FrontPageComponent,
		WallHandlerComponent,
		FilterableEventsHandlerBaseComponent,
		HandlerActionButtonComponent,
		LoginModalComponent,
		SimplePromptModalComponent,
		CropperModalComponent,
		NavIconComponent,
		FlagEventComponent,
		UserCardListComponent,
		CountryFlagComponent,
		HeaderAppComponent,
		HeaderHandlerComponent,
		SelectCountryComponent,
		RankingHandlerComponent,
		FlagsHandlerComponent,
		FlagEventListFilteredComponent,
		UserCardComponent,
		UserCardBigComponent,
		UserCountryComponent,
		UserCountryListComponent,
		HotnessColorPipe,
		ViewableActionsPipe,
		SortUsersPipe,
		ExtendedMenuComponent,
		FriendActionsComponent,
		SubscribableBaseComponent,
		WorldMapComponent,
		WorldCountryListComponent,
		StaticFooterComponent,
		CropperComponent,
		CheckSamePassDirective,
		CookiesAlertComponent,
		InfiniteScrollerDirective
	],
	exports: [
		CommonModule,
		RouterModule,
		FormsModule,
		MaterialModule,
		NavIconComponent,
		FlagEventComponent,
		UserCardListComponent,
		CountryFlagComponent,
		HeaderAppComponent,
		SelectCountryComponent,
		RankingHandlerComponent,
		FlagsHandlerComponent,
		FlagEventListFilteredComponent,
		HeaderHandlerComponent,
		FilterableEventsHandlerBaseComponent,
		HandlerActionButtonComponent,
		UserCardComponent,
		UserCardBigComponent,
		UserCountryComponent,
		UserCountryListComponent,
		HotnessColorPipe,
		ViewableActionsPipe,
		SortUsersPipe,
		FriendActionsComponent,
		ExtendedMenuComponent,
		SubscribableBaseComponent,
		WorldMapComponent,
		WorldCountryListComponent,
		StaticFooterComponent,
		CropperComponent,
		CheckSamePassDirective,
		CookiesAlertComponent,
		InfiniteScrollerDirective
	],
	providers: [
		AuthInterceptor,
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> { return { ngModule: SharedModule }; }
}
