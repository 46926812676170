import Cropper from 'cropperjs';
import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';

interface ImageCropperSetting {
	width: number;
	height: number;
}

interface ImageCropperResult {
	imageData: Cropper.ImageData;
	cropData: Cropper.CropBoxData;
	blob?: Blob;
	dataUrl?: string;
}

/**
 * Angular implementation of Cropper.js 'forked' from https://github.com/matheusdavidson/angular-cropperjs
 */
@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'dflgr-cropper',
	standalone: true,
	imports: [NgIf],
	templateUrl: './cropper.component.html',
	styleUrls: ['./cropper.component.scss']
})
export class CropperComponent {

	@ViewChild('image') readonly image: ElementRef;

	@Input({ required: true }) imageUrl: any;
	@Input() settings: ImageCropperSetting;
	@Input() cropbox: Cropper.CropBoxData;
	@Input() loadImageErrorText: string;
	@Input() cropperOptions: any = {};

	@Output() readonly export = new EventEmitter<ImageCropperResult>();
	@Output() readonly ready = new EventEmitter<boolean>();

	public isLoading = true;
	public cropper: Cropper;
	public imageElement: HTMLImageElement;
	public loadError: any;

	/**
	 * Image loaded
	 * @param ev
	 */
	imageLoaded(ev: Event) {
		// Unset load error state
		this.loadError = false;

		// Setup image element
		const image = ev.target as HTMLImageElement;
		this.imageElement = image;

		// Add crossOrigin?
		if (this.cropperOptions.checkCrossOrigin) {
			image.crossOrigin = 'anonymous';
		}

		// Image on ready event
		image.addEventListener('ready', () => {
			// Emit ready
			this.ready.emit(true);

			// Unset loading state
			this.isLoading = false;

			// Validate cropbox existance
			if (this.cropbox) {

				// Set cropbox data
				this.cropper.setCropBoxData(this.cropbox);
			}
		});

		// Setup aspect ratio according to settings
		let aspectRatio = NaN;
		if (this.settings) {
			const { width, height } = this.settings;
			aspectRatio = width / height;
		}

		// Set crop options
		// extend default with custom config
		this.cropperOptions = Object.assign({
			aspectRatio,
			movable: false,
			scalable: false,
			zoomable: false,
			viewMode: 1,
			checkCrossOrigin: true
		}, this.cropperOptions);

		// Set cropperjs
		this.cropper = new Cropper(image, this.cropperOptions);
	}

	/**
	 * Image load error
	 */
	imageLoadError() {
		// Set load error state
		this.loadError = true;

		// Unset loading state
		this.isLoading = false;
	}

}
