import { Pipe, PipeTransform } from '@angular/core';
import type User from '../models/user.model';

export enum SORTBY { times = 'times', first = 'first', last = 'last' }

@Pipe({
	name: 'sortUsers',
	standalone: true
})
export class SortUsersPipe implements PipeTransform {

	transform(users: User[], sortBy: SORTBY): User[] {
		return users && [...users].sort((userA: User, userB: User) => {
			switch (sortBy) {
				case SORTBY.first:
					return userA.userFlag.first.getTime() - userB.userFlag.first.getTime();
				case SORTBY.last:
					return userB.userFlag.last.getTime() - userA.userFlag.last.getTime();
				case SORTBY.times:
				default:
					return userB.userFlag.times - userA.userFlag.times;
			}
		});
	}

}
