import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import type { IAlertMsg } from './alert.interfaces';

@Injectable({ providedIn: 'root' })
export class AlertService {

	private alert = new Subject<IAlertMsg>();

	getProvider() {
		return this.alert.asObservable();
	}

	publish(alert: IAlertMsg) {
		this.alert.next(alert);
	}
}
