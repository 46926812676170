<mat-card itemscope itemtype="http://schema.org/AchieveAction">
	<mat-card-content>
		<div class="card-content">
			<div class="card-avatar">
				<a [routerLink]="userLink" [attr.aria-label]="'User picture for' + flagEvent?.user.username">
					<img matCardAvatar loading="lazy" [ngStyle]="defaultAvatarBgStyle" alt="User picture" class="avatar" [alt]="flagEvent?.user.username" [src]="flagEvent?.user.avatarSrc"/>
				</a>
			</div>
			<div class="card-main" tabindex="0" aria-label="Event text">
				<span class="card-username" itemprop="agent" itemscope itemtype="http://schema.org/Person">
					<span itemprop="name">{{flagEvent?.user.username}}</span>
					<meta itemprop="url" [content]="flagEvent?.user.linkStr"/>
				</span>
				<span class="card-achievement" itemprop="actionStatus" content="CompletedActionStatus">
					<ng-template #eventFlag let-second="isSecond" let-country="country" let-verb="verb" let-text="text">
						<span *ngIf="second">and</span>
						<span> {{verb}} </span>
						<span itemprop="result">{{text}}
							<dflgr-country-flag [country]="country" [showFlag]="false"/>
						</span>
					</ng-template>

					<ng-template [ngTemplateOutlet]="eventFlag" [ngTemplateOutletContext]="{ verb: verb1, country: flagEvent?.country1, text: 'the flag of' }"/>
					<ng-template *ngIf="flagEvent?.country2" [ngTemplateOutlet]="eventFlag" [ngTemplateOutletContext]="{ isSecond: true, verb: verb2, country: flagEvent?.country2, text: '' }"/>

				</span>
			</div>
			<div class="card-flags">
				<ng-template #cardFlag let-country>
					<a [routerLink]="country.link" class="country-link" [attr.aria-label]="'Flag of ' + country.name">
						<dflgr-country-flag [country]="country" [showName]="false"/>
					</a>
				</ng-template>

				<ng-template [ngTemplateOutlet]="cardFlag" [ngTemplateOutletContext]="{ $implicit: flagEvent?.country1 }"/>
				<ng-template *ngIf="flagEvent?.country2" [ngTemplateOutlet]="cardFlag" [ngTemplateOutletContext]="{ $implicit: flagEvent?.country2 }"/>
			</div>
		</div>
		<div class="card-comment" *ngIf="flagEvent?.text || flagEvent?.img" tabindex="0" aria-label="Event comments">
			<img *ngIf="flagEvent?.img" loading="lazy" class="event-pic" [src]="longText ? flagEvent.imgSrc : flagEvent.thumbSrc" alt="Event picture"/>
			<br *ngIf="flagEvent?.img && flagEvent?.text"/>
			<span *ngIf="flagEvent?.text" class="comment-content" [ngClass]="{'comment-content-clamped': !longText}">{{flagEvent.text}}</span>
		</div>
	</mat-card-content>
	<div class="mat-card-footer card-footer">
		<button mat-icon-button [ngClass]="{ 'social-status': true, 'event-liked': haveLiked, 'event-unliked': !haveLiked }" title="Like or dislike event" (click)="requestToggleLike(!haveLiked)">
			<mat-icon aria-label="Like status" [attr.aria-checked]="haveLiked" [class.mat-primary]="haveLiked">thumb_up</mat-icon>
		</button>
		<span class="likes-count">
			@if (flagEvent?.likes.length) {
				@if (haveLiked) {
					You @if (flagEvent?.likes.length > 1) { and {{flagEvent?.likes.length - 1}} more }
				} @else {
					{{flagEvent?.likes.length}}
				}
			}
		</span>
		<a class="card-link" aria-label="Event date" [routerLink]="flagEvent?.whenlink" (click)="preCacheEvent()">
			<mat-icon class="card-link-icon" aria-label="Time icon">access_time</mat-icon>
			<time itemprop="startTime" [dateTime]="flagEvent?.eventDate | date:'yyyy-MM-ddTHH:mm:ss.SSSZ'">{{flagEvent?.eventDate | date:'mediumDate'}}</time>
			<meta itemprop="url" [content]="flagEvent?.plainWhenlink"/>
		</a>
		<dflgr-extended-menu *ngIf="userIsSelf" class="self-actions" [letContent]="true">
			<button mat-menu-item *ngIf="canShare" class="share-flag-event-button" (click)="doShare()">
				<mat-icon>share</mat-icon><span>Share</span>
			</button>
			<button mat-menu-item class="edit-flag-event-button" (click)="requestEditFlag()">
				<mat-icon>edit</mat-icon><span>Edit</span>
			</button>
			<button mat-menu-item class="delete-flag-event-button" (click)="requestDelFlag()">
				<mat-icon>cancel</mat-icon><span>Delete</span>
			</button>
		</dflgr-extended-menu>
	</div>
</mat-card>
