import { Component, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { GENERAL } from '../../../app.constants';
import { IMenuOptions } from '../../services/header.interfaces';
import { FlagsService } from '../../services/flags.service';
import { FilterableEventsHandlerBaseComponent } from '../filterable-events-handler-base/filterable-events-handler-base.component';
import { HandlerActionButtonComponent } from '../../components/handler-action-button/handler-action-button.component';
import { FlagEventListFilteredComponent } from '../../components/flag-event-list-filtered/flag-event-list-filtered.component';
import { HeaderHandlerComponent } from '../../components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-wall-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, FlagEventListFilteredComponent, HandlerActionButtonComponent],
	templateUrl: './wall-handler.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./wall-handler.component.scss']
})
export class WallHandlerComponent extends FilterableEventsHandlerBaseComponent {
	@HostBinding('class.handler') readonly handlerClass = true;

	constructor(
		public readonly cdRef: ChangeDetectorRef,
		private readonly router: Router,
		private readonly flagsProvider: FlagsService
	) { super(); }

	get actionButtonAction() {
		return this.requestAddFlag.bind(this);
	}

	get handlerOptions(): IMenuOptions[] {
		return [{
			text: 'View all countries',
			icon: 'language',
			forceShowAction: true,
			callback: () => this.router.navigate([GENERAL.handlers.countries.path])
		}];
	}

	protected toggleFilter(val) {
		super.toggleFilter(val);
		this.cdRef.detectChanges();
	}

	private requestAddFlag() {
		this.flagsProvider.requestAddFlag();
	}
}
