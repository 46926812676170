import { Pipe, PipeTransform } from '@angular/core';
import type { IMenuOptions } from '../services/header.interfaces';

@Pipe({
	name: 'viewableActions',
	standalone: true
})
export class ViewableActionsPipe implements PipeTransform {

	transform(items: IMenuOptions[], reversed?: boolean): IMenuOptions[] {
		return items?.filter(item => reversed ? !item.forceShowAction : item.forceShowAction);
	}

}
