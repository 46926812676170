import Country from './country.model';
import { PATHS, FRIENDSTATUS, GENERAL } from '../../app.constants';
import Utils from '../app.utils';

export enum GENDER {male = 'male', female = 'female'}

export default class User {
	readonly id: string;
	username: string;
	name: string;
	surname: string;
	birth: Date;
	gender?: GENDER;
	country1: Country;
	country2: Country = null;
	bio?: string;
	avatar: any;
	userFlag?: { country: Country; times: number; first: Date; last: Date; };
	countriesList: Country[];
	friendStatus: FRIENDSTATUS;
	email1?: string;

	constructor (userId?: string) {
		if (userId) {
			this.id = userId.toString();
		}
	}

	initialize(
		username: string,
		name: string,
		surname: string,
		birth: any,
		gender: GENDER,
		country1: string,
		country2?: string,
		avatar?: any,
		friendStatus?: FRIENDSTATUS,
		bio?: string
	): User {
		this.username = username;
		this.name = name;
		this.surname = surname;
		this.birth = birth ? new Date(birth) : birth;
		this.country1 = new Country(country1);
		this.country2 = country2 ? new Country(country2) : null;
		this.gender = gender;
		this.avatar = avatar;
		this.bio = bio;
		if (friendStatus) {
			this.friendStatus = friendStatus;
		}
		return this;
	}

	initializeFromRes(
		username: string,
		name: string,
		surname: string,
		gender: GENDER,
		country1: string,
		country2?: string,
		avatar?: any
	): User {
		this.username = username;
		this.name = name;
		this.surname = surname;
		this.gender = gender;
		this.country1 = new Country(country1);
		this.country2 = country2 ? new Country(country2) : null;
		this.avatar = avatar;
		return this;
	}

	initializeUserFlag(
		username: string,
		name: string,
		surname: string,
		gender: GENDER,
		flagCountryId: string,
		first: string,
		last: string,
		times: number,
		userCountry1: string,
		userCountry2?: string,
		avatar?: any
	) {
		this.username = username;
		this.name = name;
		this.surname = surname;
		this.country1 = new Country(userCountry1);
		this.country2 = userCountry2 ? new Country(userCountry2) : null;
		this.gender = gender;
		this.avatar = avatar;
		const country = new Country(flagCountryId);
		this.userFlag = { country, first: new Date(first), last: new Date(last), times };
		return this;
	}

	initializeToRegister(
		username: string,
		name: string,
		surname: string,
		gender: GENDER,
		country1: Country,
		country2?: Country,
		birth?: any
	): User {
		this.username = username;
		this.name = name;
		this.surname = surname;
		this.birth = !!birth ? new Date(birth) : null;
		this.country1 = country1;
		this.country2 = country2 ? country2 : null;
		this.gender = gender;
		return this;
	}

	get fullName(): string {
		return `${this.name ?? ''} ${this.surname ?? ''}`;
	}

	get hasName(): boolean {
		return this.fullName.trim() !== '';
	}

	get avatarSrc(): string {
		if (this.avatar) {
			return PATHS.avatars + this.avatar;
		}

		return this.defaultAvatarSrc;
	}

	private get defaultAvatarSrc() {
		return `/${PATHS.images}/default_${this.gender || 'female'}.png`;
	}

	get defaultAvatarBgSrc() {
		return { 'background-image': `url(${this.defaultAvatarSrc})` };
	}

	get link(): string[] {
		return [`/${PATHS.user}`, this.username];
	}

	get linkStr(): string {
		return `/${PATHS.user}/${this.username}`;
	}

	get genderIcon() {
		switch (this.gender) {
			case GENDER.female: return String.fromCodePoint(0x1F6BA); // female sign 🚺
			case GENDER.male: return String.fromCodePoint(0x1F6B9); // male sign 🚹
			default: return '';
		}
	}

	get birthStr() {
		return this.birth ? `${this.birth.getFullYear()}-${
			Utils.fix2digit(this.birth.getMonth() + 1)}-${
			Utils.fix2digit(this.birth.getDate())
		}` : '';
	}

	get isFriend() {
		return this.friendStatus === FRIENDSTATUS.FRIEND;
	}

	get isPending() {
		return this.friendStatus === FRIENDSTATUS.REQUESTED;
	}

	get isBlocked() {
		return this.friendStatus === FRIENDSTATUS.BLOCKED;
	}

	get isNotFriend() {
		return !this.isFriend && !this.isBlocked;
	}

	getUserCountryLink(country: Country) {
		return [ ...this.link, GENERAL.handlers.user.flags.path, country?.id ];
	}
}
