import { GENERAL } from '../../app.constants';
import { en as countriesEN } from '@jagomf/countrieslist';

export default class Country {

	id: string;
	city?: string;

	set fromId(countryId: string) {
		this.id = countryId;
	}

	private static getIdfromName(name: string): string {
		const found = Object.entries(countriesEN) // TODO MultiLang
			.find(([, val]) => val.toLowerCase() === name.toLowerCase());
		return found?.[0].toLowerCase();
	}

	constructor(idOrName?: string, city?: string) {
		if (idOrName) {
			if (idOrName.length === 2) {
				this.id = idOrName.toLowerCase();
			} else {
				const id = Country.getIdfromName(idOrName);
				if (id) {
					this.id = id;
				} else {
					throw { message: 'Country name is not valid' };
				}
			}
			if (city) {
				this.city = city;
			}
		}
	}

	get name(): string {
		return countriesEN[this.id.toUpperCase()];
	}

	get link(): string[] {
		return [`/${GENERAL.handlers.countries.path}`, this.id];
	}
}
