<mat-card>
	<mat-card-header class="card-header">
		<img matCardAvatar class="avatar" loading="lazy" [ngStyle]="defaultAvatarBgStyle" [alt]="user?.username" [routerLink]="userLink" [src]="user?.avatarSrc"/>
		<mat-card-title class="name moderate-card-title">
			<a [routerLink]="userLink">
				<span>@if (user?.hasName) { {{user?.name}} {{user?.surname}} } @else { {{user?.username}} }</span>
			</a>
		</mat-card-title>
		<mat-card-subtitle *ngIf="user?.hasName" class="username"><a [routerLink]="userLink">{{user?.username}}</a></mat-card-subtitle>
		<div class="h-spacer"></div>
		<div class="extra">
			<span *ngIf="userIsGroupAdmin" class="chip-item group-admin"><mat-icon>star</mat-icon> Admin</span>
			<span *ngIf="user?.isFriend" class="chip-item user-friend">Friend</span>
			<span *ngIf="user?.isBlocked" class="chip-item user-blocked">Blocked</span>
			<span *ngIf="user?.isPending" class="chip-item user-pending">Pending</span>
		</div>
		<div class="card-flags">
			<dflgr-country-flag *ngIf="user?.userFlag" [country]="user.userFlag.country" [showName]="false"/>
		</div>
		<dflgr-extended-menu *ngIf="group && (selfIsGroupAdmin || userIsSelf)" [letContent]="true">
			<button mat-menu-item *ngIf="selfIsGroupAdmin && !userIsSelf" (click)="makeAdmin.emit();">
				<mat-icon>star_border</mat-icon>
				<span>Make admin</span>
			</button>
			<button mat-menu-item class="ext-item" [class.danger]="userIsSelf" (click)="removeFromGroup.emit()">
				<mat-icon>@if (userIsSelf) { directions_run } @else { delete }</mat-icon>
				<span>@if (userIsSelf) { Leave } @else { Remove from }</span> group
			</button>
		</dflgr-extended-menu>
		<dflgr-extended-menu *ngIf="user?.isFriend" [letContent]="true">
			<dflgr-friend-actions
				[friend]="user"
				[vertical]="true"
				[isFriendRequest]="isFriendRequest"
				(statusChanged)="statusChanged.emit()"/>
		</dflgr-extended-menu>
	</mat-card-header>
	<mat-card-content class="card-content">
		<div class="user-details">
			<a [routerLink]="user?.country1.link">
				<dflgr-country-flag [country]="user?.country1" [showName]="false"/>
			</a>
			<a *ngIf="user?.country2" [routerLink]="user?.country2.link">
				<dflgr-country-flag [country]="user?.country2" [showName]="false"/>
			</a>
			<!-- span class="gender" *ngIf="showFriendActions">{{user?.genderIcon}}</span -->
		</div>
		<div class="card-main">
			<div *ngIf="user?.userFlag" class="user-flag">
				<div>Times: <a [routerLink]="getUserCountryLink(user?.userFlag.country)">&times;{{user?.userFlag.times}}</a></div>
				@if (user?.userFlag.times > 1) {
					<div>First: {{user?.userFlag.first | date}}</div>
					<div>Last: {{user?.userFlag.last | date}}</div>
				} @else {
					<div>On {{user?.userFlag.first | date}}</div>
				}
			</div>
			<div *ngIf="showFriendActions && user?.countriesList">
				<span>Flags: {{user?.countriesList.length}}</span>
			</div>
		</div>
	</mat-card-content>
	<mat-card-actions *ngIf="showFriendActions && !user?.isFriend">
		<dflgr-friend-actions
			[friend]="user"
			[isFriendRequest]="isFriendRequest"
			(statusChanged)="statusChanged.emit()"/>
	</mat-card-actions>
</mat-card>
