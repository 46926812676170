import { trigger, style, transition, animate } from '@angular/animations';

export const inOutItems = trigger('inOutItems', [
	transition(':enter', [
		style({transform: 'scale(0.3)', opacity: 0.1}),
		animate('0.3s ease-in', style({transform: 'scale(1)', opacity: 1}))
	]),
	transition(':leave', [
		animate('0.3s ease-out', style({transform: 'scale(0.3)', opacity: 0.1}))
	])
]);
