import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, of as ObservableOf } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export abstract class ConnectedService {

	protected makeEndpoint(opEndpoint: string, urlParts?: object): string {
		const endpointPath = urlParts ? Object.entries(urlParts).reduce((acc, [currKey, currVal], i) =>
			acc + (i ? '/' : '') + opEndpoint.replace(`{${currKey}}`, currVal)
		, '') : opEndpoint;
		return `${environment.API.endpoint}${endpointPath}`;
	}

	protected makeParams(params: object): HttpParams {
		return Object.entries(params).reduce(
			(acc, [key, val]) => acc.set(key, val), new HttpParams()
		);
		// Angular 5 (if no numbers, just strings, were used as values):
		// return new HttpParams({ fromObject: params });
	}

	protected handleError<T>(error: any, defaultErrVal?: T, shouldThrowError?: boolean) {
		let errMsg: string;
		if (error instanceof HttpErrorResponse) {
			errMsg = `${error.status} - ${error.statusText || ''} ${error.message}`;
		} else if (error instanceof Error) {
			errMsg = error.name + ' ' + error.message;
		} else {
			errMsg = error.message || error.toString();
		}
		console.error(errMsg);
		if (shouldThrowError) {
			return throwError(error);
		}
		return ObservableOf(defaultErrVal);
	}
}
