import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import type { IHeaderFormat } from './header.interfaces';

@Injectable({ providedIn: 'root' })
export class HeaderService {

	private readonly notifier = new Subject<IHeaderFormat>();

	onHeaderSet() {
		return this.notifier.asObservable();
	}

	setHeader(data: IHeaderFormat) {
		this.notifier.next(data);
	}
}
