import { Component } from '@angular/core';
import { EVENTSWITHFRIENDS } from '../../../app.constants';
import type { IMenuFilter } from '../../services/header.interfaces';

@Component({
	template: '',
	standalone: true
})
export class FilterableEventsHandlerBaseComponent {

	showOnlyBy = EVENTSWITHFRIENDS.YES;

	get handlerFilters(): IMenuFilter[] {
		return [];
		/*
		return [
			{
				text: 'Friends',
				selected: this.showOnlyBy === EVENTSWITHFRIENDS.YES || this.showOnlyBy === EVENTSWITHFRIENDS.ONLY,
				disabled: this.showOnlyBy === EVENTSWITHFRIENDS.ONLY,
				callback: this.toggleFilter.bind(this, EVENTSWITHFRIENDS.ONLY)
			},
			{
				text: 'Mine',
				selected: this.showOnlyBy === EVENTSWITHFRIENDS.YES || this.showOnlyBy === EVENTSWITHFRIENDS.NO,
				disabled: this.showOnlyBy === EVENTSWITHFRIENDS.NO,
				callback: this.toggleFilter.bind(this, EVENTSWITHFRIENDS.NO)
			}
		] as IMenuFilter[];
		*/
	}

	protected toggleFilter(val: EVENTSWITHFRIENDS): void {
		switch (val) {
			case EVENTSWITHFRIENDS.ONLY:
				this.showOnlyBy = this.showOnlyBy === EVENTSWITHFRIENDS.YES ? EVENTSWITHFRIENDS.NO : EVENTSWITHFRIENDS.YES;
				break;
			case EVENTSWITHFRIENDS.NO:
				this.showOnlyBy = this.showOnlyBy === EVENTSWITHFRIENDS.YES ? EVENTSWITHFRIENDS.ONLY : EVENTSWITHFRIENDS.YES;
				break;
		}
	}

}
