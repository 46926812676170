import { Component, Input, HostBinding, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
	animations: [
		trigger('scaleInOut', [
			transition(':enter', [
				style({transform: 'scale(0)'}),
				animate('350ms ease-in', style({transform: 'scale(1)'}))
			]),
			transition(':leave', [
				style({transform: 'scale(1)'}),
				animate('350ms ease-out', style({transform: 'scale(0)'}))
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dflgr-handler-action-button',
	standalone: true,
	imports: [NgIf, MatButtonModule, MatIconModule],
	templateUrl: './handler-action-button.component.html',
	styleUrls: ['./handler-action-button.component.scss']
})
export class HandlerActionButtonComponent {
	@HostBinding('attr.role') readonly role = 'button';
	@HostBinding('class') class = 'handler-action-button';
	@Input({ required: true }) icon: string;
	@Input({ required: true }) action: () => {};
	@HostBinding('attr.aria-label')
	@Input() label?: string;
	@HostBinding('attr.aria-hidden') get ariaHidden() {
		return !this.action;
	}
	@HostBinding('tabindex') get tabIndex() {
		return this.action ? 0 : -1;
	}
	@HostListener('click', ['$event']) click(ev: MouseEvent) {
		this.performActionReq(ev);
	}
	@HostListener('keypress', ['$event']) keypress(ev: KeyboardEvent) {
		if ((ev.code === 'Space' || ev.code === 'Enter')
			&& !ev.shiftKey && !ev.ctrlKey && !ev.altKey && !ev.metaKey
		) {
			this.performActionReq(ev);
		}
	}

	private performActionReq(ev: Event) {
		ev.stopPropagation();
		ev.preventDefault();
		this.action();
	}
}
