import { Component, Input, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Country from '../../models/country.model';
import { GENERAL } from '../../../app.constants';
import { HeaderService } from '../../services/header.service';
import type { IMenuOptions, IMenuFilter } from '../../services/header.interfaces';

@Component({
	selector: 'dflgr-header-handler',
	standalone: true,
	templateUrl: './header-handler.component.html',
	styleUrls: ['./header-handler.component.scss']
})
export class HeaderHandlerComponent implements OnInit, OnChanges {

	@Input() title?: string;
	@Input() flag?: Country;
	@Input() backButton?: boolean | string = false;
	@Input() options?: IMenuOptions[];
	@Input() filters?: IMenuFilter[];
	@Input() sorters?: IMenuFilter[];

	constructor(
		private readonly headerProvider: HeaderService,
		private readonly appTitle: Title,
	) {
		this.setTitle();
	}

	ngOnInit() {
		this.setTitleAndHeader();
	}

	private setTitle(title?: string): void {
		this.appTitle.setTitle(`${GENERAL.app.title}${ title ? ` | ${title}` : ''}`);
	}

	private setTitleAndHeader(): void {
		this.setTitle(this.title);
		this.setRemoteHeader();
	}

	private setRemoteHeader() {
		this.headerProvider.setHeader(this.title || this.flag || this.filters ? {
			text: this.title,
			backButton: this.backButton,
			country: this.flag,
			filters: this.filters,
			sorters: this.sorters,
			actions: this.options
		} : null);
	}

	ngOnChanges({ flag, title, options, filters }: { flag: SimpleChange, title: SimpleChange, options: SimpleChange, filters: SimpleChange }) {
		if ((flag && !flag.isFirstChange()) || (title && !title.isFirstChange())
			|| (options && !options.isFirstChange()) || (filters && !filters.isFirstChange())) {
			this.setTitleAndHeader();
		}
	}

}
