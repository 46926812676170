<dflgr-header-handler/>

<header>
	<div class="background-sky hero"></div>
	<section id="intro">
		<div class="hero-logo"></div>
		<div class="homepage-container">
			<div class="hero-headline">
				<span class="hero-headline-title">{{title}}</span>
				<br/><span class="hero-headline-tag">Collect world flags</span>
			</div>
			<div class="hero-stores">
				<a [href]="appleStore" target="_blank" class="store apple-store">Get app from Apple App Store</a>
				<a [href]="googleStore" target="_blank" class="store google-store">Get app from Google Play</a>
			</div>
			<!-- div class="hero-cta">
				<button mat-raised-button (click)="requestOpenLogin();">SIGN IN</button> or
				<a mat-raised-button color="accent" routerLink="/register">SIGN UP free!</a>
			</div -->
		</div>
		<!-- TODO: Google Play & Apple App Store buttons -->
	</section>
</header>

<article>
	<h1 class="no-anchor no-toc" style="display: none" id=""></h1>
	<div class="home-rows">
		<div class="home-row">
			<div class="img-container">
				<!-- <img loading="lazy"/> -->
			</div>
			<div class="text-container">
				<div class="text-headline">Keep a track of your world interactions</div>
				<p class="text-body">In {{title}}, you can <em>earn flags</em> in any way you can imagine: by travelling, making businesses, meeting people... Where have you been? Where are they from? <b>You define your own rules!</b><br/>Just add your <em>flag event</em> to your record, and you're good to go!</p>
			</div>
		</div>
		<hr/>
		<div class="home-row">
			<div class="img-container">
				<!-- <img loading="lazy"/> -->
			</div>
			<div class="text-container">
				<div class="text-headline">Share them with your friends</div>
				<p class="text-body">You can track your <b>friends</b>' flags and like them. Also <b>rank</b> them to see where you are on the race for the most flags.<br/>Keep different people in <b>Groups</b>, just like in real life. Groups get their own rankings and walls.</p>
			</div>
		</div>
		<hr/>
		<div class="home-row">
			<div class="img-container">
				<!-- <img loading="lazy"/> -->
			</div>
			<div class="text-container">
				<div class="text-headline">Cover the whole world</div>
				<p class="text-body">Watch your <b>World Map</b> to see how your flag events translate into <em>colored countries</em> so that you know where to target next.<br/>It also helps tracing back to old events you or your friends created so that you can always remember goold old stuff.</p>
			</div>
		</div>
		<hr/>
		<div class="home-row">
			<div class="img-container">
				<!-- <img loading="lazy"/> -->
			</div>
			<div class="text-container">
				<div class="text-headline">Who's been here and there?</div>
				<p class="text-body">Check flag events <b>by country</b> so that you know who of your friends (or yourself in case you forgot) already <em>got</em> that flag.<br/>Check and like any past and present flag event in case you missed them, whether by friend, by country, or by group.</p>
			</div>
		</div>
	</div>
</article>

<dflgr-cookies-alert/>
<dflgr-static-footer [show-hr]="false"/>
