import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { GENERAL, PATHS, APP } from '../../../app.constants';
import { AuthService } from '../../services/auth.service';
import { StaticFooterComponent } from '../../components/static-footer/static-footer.component';
import { CookiesAlertComponent } from '../../components/cookies-alert/cookies-alert.component';
import { MatButtonModule } from '@angular/material/button';
import { HeaderHandlerComponent } from '../../components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-front-page',
	standalone: true,
	imports: [HeaderHandlerComponent, MatButtonModule, RouterLink, CookiesAlertComponent, StaticFooterComponent],
	templateUrl: './front-page.component.html',
	styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {
	readonly title = GENERAL.app.title;
	readonly googleStore = APP.android.store;
	readonly appleStore = APP.ios.store;

	constructor(
		private readonly authProvider: AuthService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) { }

	ngOnInit() {
		if (this.authProvider.isLogged) {
			this.router.navigate([`/${PATHS.wall}`]);
		} else if (this.route.snapshot.url?.[0]?.path === 'login') {
			this.authProvider.requestLogin(this.route.snapshot.queryParams?.returnUrl);
		}
	}

	requestOpenLogin() {
		this.authProvider.requestLogin();
	}

}
