<div>
	<form #loginForm="ngForm" (ngSubmit)="doLogin(loginForm)">
		<h2 matDialogTitle>Login</h2>
		<mat-dialog-content>
			<mat-form-field class="full-width">
				<mat-label>Username or email</mat-label>
				<input type="text" inputmode="email" matInput name="user" ngModel #user="ngModel" placeholder="Username or email" autocapitalize="none" autocomplete="username" required/>
			</mat-form-field>
			<mat-form-field class="full-width">
				<mat-label>Password</mat-label>
				<input type="password" matInput name="pass" ngModel #pass="ngModel" placeholder="Password" autocomplete="current-password" required/>
				<mat-hint align="end"><a [routerLink]="recoverLink" (click)="dialogRef.close()">Forgot your password?</a></mat-hint>
			</mat-form-field>
			<input type="hidden" name="type" ngModel #type="ngModel" value="web"/>
			<mat-progress-bar *ngIf="isLogging" mode="indeterminate"/>
			<div class="logging-error" *ngIf="hasError">Could not login!</div>
		</mat-dialog-content>
		<mat-dialog-actions [attr.align]="actionsAlignment">
			<button mat-raised-button color="accent" type="submit" [disabled]="isLogging || loginForm.invalid"><mat-icon>vpn_key</mat-icon> <span>Login</span></button>
			<button mat-button matDialogClose [disabled]="isLogging"><mat-icon>cancel</mat-icon> <span>Cancel</span></button>
		</mat-dialog-actions>
	</form>
</div>
