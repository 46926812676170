import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of as ObservableOf } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Utils from '../../shared/app.utils';
import { FLAGEVENTS, EVENTSWITHFRIENDS } from '../../app.constants';
import Country from '../models/country.model';
import FlagEventModel from '../models/flag-event.model';
import Group from '../models/group.model';
import User from '../models/user.model';
import { FLAGS as FlagsURLs } from '../../api.constants';
import { ConnectedService } from './connected.service';
import { StorageService } from './storage.service';
import type { IFlagUser } from './flags.interfaces';

@Injectable({ providedIn: 'root' })
export class FlagsService extends ConnectedService {

	// flagEventCreated = new EventEmitter<FlagEventModel>();
	private readonly flagEventCreatedOrEditedSub = new Subject<FlagEventModel>();

	resolvedEvent: FlagEventModel;

	private readonly addFlagRequested = new Subject<Country>();

	constructor(
		private readonly httpConn: HttpClient,
		private readonly storageService: StorageService,
	) {
		super();
	}

	private get countriesList(): Country[] {
		return this.storageService.countries;
	}
	private set countriesList(val: Country[]) {
		this.storageService.countries = val;
	}

	/**
	 * Inicializa una lista de resultados
	 */
	private initializeFlagEvents(newRawEvents: any[], userList: any[]): FlagEventModel[] {
		// Convertimos a FlagEvent todos los eventos nuevos
		return newRawEvents.map((item: any) => {
			const userRaw = userList.find(userData => userData.id === item.user);
			const user = new User(userRaw.id).initializeFromRes(
				userRaw.username, userRaw.name, userRaw.surname, userRaw.gender, userRaw.country1, userRaw.country2, userRaw.avatar
			);

			return new FlagEventModel(item.id, item.country1, item.country2 || null, user,
				item.text, item.img, Utils.getDateFromUTCString(item.when, FLAGEVENTS.dateFromBack),
				item.accumulated1, item.accumulated2 || null, item.likes
			);
		});
	}

	requestAddFlag(country?: Country) {
		this.addFlagRequested.next(country);
	}

	likeEvent(event: FlagEventModel, like: boolean): Observable<boolean> {
		const { endp, meth } = like ? FlagsURLs.EVENTLIKE : FlagsURLs.EVENTUNLIKE;
		const endpoint = this.makeEndpoint(endp, { eventid: event.id });

		return this.httpConn.request<void>(meth, endpoint, { body: {}, observe: 'response' }).pipe(
			map(res => res.status === 204),
			catchError((e) => this.handleError(e, null))
		);
	}

	addFlagRequests() {
		return this.addFlagRequested.asObservable();
	}

	getCountriesList(forceLoad?: boolean): Observable<Country[]> {
		if (this.countriesList && !forceLoad) {
			return ObservableOf(this.countriesList);
		}

		const { endp, meth } = FlagsURLs.GETCOUNTRIESLIST;
		const endpoint = this.makeEndpoint(endp);

		return this.httpConn.request<{ code: string; city: string; }[]>(meth, endpoint).pipe(
			map((res) => res.map(country => new Country(country.code, country.city)).sort(
				(cA, cB) => (cA.name < cB.name) ? -1 : (cA.name > cB.name) ? 1 : 0) // alphabetical sort
			),
			tap((countries) => this.countriesList = countries),
			catchError((e) => this.handleError(e, []))
		);
	}

	addFlagEvent(country1: Country, country2: Country, whenUTC: string, text: string, base64img: string): Observable<FlagEventModel> {
		const { endp, meth } = FlagsURLs.ADDFLAGEVENT;
		const endpoint = this.makeEndpoint(endp);
		const body = {
			country1: country1.id,
			country2: country2?.id,
			when: whenUTC,
			text,
			base64img,
			from: 'web'
		};

		return this.httpConn.request<any>(meth, endpoint, { body }).pipe(
			map((res) => {
				const user = this.storageService.localUser;
				const newFlagEvent = new FlagEventModel(
					res.id, res.country1, res.country2, user, res.text, res.img,
					Utils.getDateFromUTCString(res.when, FLAGEVENTS.dateFromBack), res.accumulated1, res.accumulated2
				);
				this.flagEventCreatedOrEditedSub.next(newFlagEvent);
				return newFlagEvent;
			}),
			catchError((e) => this.handleError(e, null))
		);
	}

	editFlagEvent(eventid: string, country1: Country, country2?: Country,
		whenUTC?: string, text?: string, base64img?: string): Observable<FlagEventModel> {
		const { endp, meth } = FlagsURLs.EDITEVENT;
		const endpoint = this.makeEndpoint(endp, { eventid });
		const edited = {};
		if (whenUTC) {
			edited['when'] = whenUTC;
		}
		if (text) {
			edited['text'] = text;
		}
		if (base64img) {
			edited['base64img'] = base64img;
		}
		const body = {
			country1: country1.id,
			country2: country2?.id ?? '',
			edited
		};

		return this.httpConn.request<any>(meth, endpoint, { body }).pipe(
			map((res) => {
				const user = this.storageService.localUser;
				const newFlagEvent = new FlagEventModel(
					res.id, res.country1, res.country2, user, res.text, res.img,
					Utils.getDateFromUTCString(res.when, FLAGEVENTS.dateFromBack), res.accumulated1, res.accumulated2
				);
				this.flagEventCreatedOrEditedSub.next(newFlagEvent);
				return newFlagEvent;
			}),
			catchError((e) => this.handleError(e, null))
		);
	}

	getFlagEvents(user: User, pageStart: number, country: Country, friends: EVENTSWITHFRIENDS, groupid: string): Observable<FlagEventModel[]> {
		const { endp, meth } = FlagsURLs.LOADUSERFLAGEVENTS;
		const endpoint = this.makeEndpoint(endp);
		const params = this.makeParams({
			userid: user?.id,
			startNum: pageStart,
			howMany: FLAGEVENTS.howManyEachTime,
			countryid: country?.id ?? '',
			friends,
			groupid: groupid || ''
		});
		const emptyRes = [];

		return this.httpConn.request<{ events: any[]; users: any; }>(meth, endpoint, { params, observe: 'response' }).pipe(
			map((res) => res.status === 204 ? emptyRes : this.initializeFlagEvents(res.body.events, res.body.users)),
			catchError((e) => this.handleError(e, emptyRes))
		);
	}

	deleteFlagEvent(flagEventId: FlagEventModel) {
		const { endp, meth } = FlagsURLs.DELETEFLAGEVENT;
		const endpoint = this.makeEndpoint(endp, { eventid: flagEventId.id });

		return this.httpConn.request(meth, endpoint).pipe(
			tap(() => {
				if (flagEventId.id === this.resolvedEvent?.id) {
					this.resolvedEvent = null;
				}
			}),
			catchError((e) => this.handleError(e, false, true))
		);
	}

	getSingleEvent(userid: string, eventDate: Date): Observable<FlagEventModel> {
		const { endp, meth } = FlagsURLs.GETSINGLEEVENT;
		const when = Utils.getUrlDate(eventDate);
		const endpoint = this.makeEndpoint(endp, { fullcode: when + userid });

		return this.httpConn.request<{ event: any; user: any; }>(meth, endpoint).pipe(
			map((res) => this.initializeFlagEvents([res.event], [res.user])[0]),
			catchError((e) => this.handleError(e, null))
		);
	}

	getRanking(groupId?: string): Observable<{ user: User, countries: Country[], rank: number }[]> {
		let meth, endpoint;
		if (groupId) {
			meth = FlagsURLs.GROUPSRANKING.meth;
			endpoint = this.makeEndpoint(FlagsURLs.GROUPSRANKING.endp, { groupId });
		} else {
			meth = FlagsURLs.USERSRANKING.meth;
			endpoint = this.makeEndpoint(FlagsURLs.USERSRANKING.endp);
		}

		return this.httpConn.request<any[]>(meth, endpoint, { observe: 'response' }).pipe(
			map((res) => res.status === 204 ? [] :
				res.body.map((item) => {
					const user: User = new User(item.user);
					user.name = item.name;
					user.surname = item.surname;
					user.username = item.username;
					user.country1 = new Country(item.selfcountry1);
					user.country2 = item.selfcountry2 ? new Country(item.selfcountry2) : null;
					// Turn a comma-separated string into a list of countries without self countries
					const countries: Country[] = item.allcountries.split(',')
						.map((countryString: string) => new Country(countryString))
						.filter((country: Country) => {
							return !(country.id === user.country1.id || country.id === user.country2?.id);
						});
					return { user, countries, rank: item.rank };
				})
			),
			catchError((e) => this.handleError(e, []))
		);
	}

	getUsersByFlag(country: Country): Observable<User[]> {
		const { endp, meth } = FlagsURLs.LOADUSERSBYFLAG;
		const endpoint = this.makeEndpoint(endp, { country: country.id });

		return this.httpConn.request<any[]>(meth, endpoint, { observe: 'response' }).pipe(
			map((res) => res.status === 204 ? [] : res.body.map((item) => new User(item.user)
				.initializeUserFlag(
					item.username, item.name, item.surname, item.gender,
					country.id, item.first, item.last, item.times,
					item.usercountry1, item.usercountry2, item.avatar
				)
			)),
			catchError((e) => this.handleError(e, []))
		);
	}

	getFlagsForUserOrGroup(input: User | Group): Observable<IFlagUser[]> {
		let isGroup = false;
		let meth, endpoint;
		if (input instanceof Group) {
			isGroup = true;
			meth = FlagsURLs.LOADGROUPFLAGS.meth;
			endpoint = this.makeEndpoint(FlagsURLs.LOADGROUPFLAGS.endp, { groupId: input.id });
		} else if (input instanceof User) {
			meth = FlagsURLs.LOADUSERFLAGS.meth;
			endpoint = this.makeEndpoint(FlagsURLs.LOADUSERFLAGS.endp, { userId: input.id });
		}

		return this.httpConn.request<any[]>(meth, endpoint, { observe: 'response' }).pipe(
			map(res => res.status === 204 ? [] :
				res.body.reduce((result: IFlagUser[], item) => {
					// check if this country is already stored in results
					let flagPos = result.findIndex((elem) => elem.country.id === item.code);

					// if country code is not stored yet, create new flagoccurrence
					if (flagPos < 0) {
						// now it is stored, save position; it is given by result.push(item)-1
						flagPos = result.push({
							country: new Country(item.code),
							items: [], // item: { user, times, isSelfCountry }
							total: 0
						}) - 1;
					}

					const currentItem = result[flagPos];

					const { user, name, surname, username, selfcountry1, selfcountry2 } = item;
					const currentUser: User = Object.assign(new User(user), {
						name, surname, username,
						country1: new Country(selfcountry1),
						country2: selfcountry2 ? new Country(selfcountry2) : null
					});

					currentItem.total += item.times;
					currentItem.items.push({
						user: currentUser,
						times: item.times || null,
						isSelfCountry: (currentUser.country1.id === item.code
							|| currentUser.country2?.id === item.code)
					});

					return result;
				}, [])
				.sort((itemA, itemB) => isGroup
					? itemB.total - itemA.total
					: itemB.items[0].times - itemA.items[0].times
				)
			),
			catchError((e) => this.handleError(e, []))
		);
	}

	flagEventCreatedOrEdited() {
		return this.flagEventCreatedOrEditedSub.asObservable();
	}
}
