import { Component, HostBinding, Input } from '@angular/core';
import Country from '../../models/country.model';
// import * as COUNTRIESLIST from '@jagomf/countrieslist';
import { en as countriesEN } from '@jagomf/countrieslist';
import { AuthService } from '../../services/auth.service';
import { flag } from 'country-code-emoji';
import { ASSETS } from '../../../app.constants';
import { NgIf, NgClass } from '@angular/common';

@Component({
	preserveWhitespaces: true,
	selector: 'dflgr-country-flag',
	standalone: true,
	imports: [NgIf, NgClass],
	templateUrl: './country-flag.component.html',
	styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent {

	@HostBinding('class.item-country-flag') readonly classItemCountryFlag = true;
	@Input() showFlag ? = true;
	@Input() showName ? = true;
	@Input({ required: true }) country: Country;
	@Input() lang ? = 'en';

	constructor(readonly authProvidetr: AuthService) {
		this.lang = this.authProvidetr.localLanguage;
	}

	get countryNameClassBindings() {
		return {
			'name-container': true,
			'hidden': !this.showName
		};
	}

	get name(): string {
		// TODO MultiLang: return this.country?.id ? COUNTRIESLIST[this.lang][this.country.id.toUpperCase()] : '';
		return this.country?.id ? countriesEN[this.country.id.toUpperCase()] : '';
	}

	get flagCode(): string {
		return this.country?.id ? flag(this.country.id) : '';
	}

	get flagImgSrc(): string {
		const flagConst = ASSETS.flags;
		return this.country?.id ? `${flagConst.path}${this.country.id}${flagConst.ext}` : '';
	}
}
