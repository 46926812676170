import { Directive, forwardRef, Input } from '@angular/core';
import { NgModel, NG_ASYNC_VALIDATORS, Validator, type FormControl } from '@angular/forms';
import { type Observable, of as ObservableOf } from 'rxjs';

@Directive({
	selector: '[dflgrCheckSamePass]',
	providers: [
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: forwardRef(() => CheckSamePassDirective),
			multi: true
		}
	],
	standalone: true
})
export class CheckSamePassDirective implements Validator {
	@Input('dflgrCheckSamePass') otherInput: NgModel;

	validate(rePass: FormControl<string>): Observable<{ notEqual: boolean }> {
		const valid = rePass.value === this.otherInput.value;
		return ObservableOf(valid ? null : { notEqual: true });
	}
}
