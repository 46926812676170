<a class="item-wrapper"
	[routerLink]="path"
	routerLinkActive #rla="routerLinkActive"
	[title]="titleText">
	<mat-icon class="icon"
		[matBadgeHidden]="badge === 0"
		aria-hidden="false"
		[matBadge]="badge?.toString()"
		matBadgeSize="small"
		[@activeNavLinks]="rla.isActive ? 'active' : 'normal'"
		matBadgeDescription="New friend requests"
		matBadgeColor="warn">{{icon}}</mat-icon>
	<span class="text" *ngIf="showTitle && text"
		[@activeNavLinks]="rla.isActive ? 'active' : 'normal'">{{text}}</span>
</a>
