import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { type Observable, fromEvent as ObservableFromEvent, of as ObservableOf, Subject } from 'rxjs';
import { exhaustMap, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';

interface ScrollPosition { sH: number; sT: number; cH: number; }

const DEFAULT_SCROLL_POSITION: ScrollPosition = { sH: 0, sT: 0, cH: 0 };

/**
 * Infinite Scroller directive.
 * @see https://codeburst.io/angular-2-simple-infinite-scroller-directive-with-rxjs-observables-a989b12d4fb1
 * @author Ashwin Sureshkumar
 */
@Directive({
	selector: '[dflgrInfiniteScroller]',
	standalone: true
})
export class InfiniteScrollerDirective implements AfterViewInit, OnDestroy {

	private scrollEvent$: Observable<any>;
	private userScrolledDown$: Observable<any>;
	// private requestStream$: Observable<any>;
	private requestOnScroll$: Observable<any>;

	private ngUnsubscribe = new Subject<boolean>();

	@Input() scrollCallback: () => any;
	@Input() isEnabled ?= true;
	@Input() immediateCallback ?= true;
	@Input() scrollPercent ?= 85;

	constructor(
		private readonly elm: ElementRef,
		private readonly cd: ChangeDetectorRef
	) { }

	private registerScrollEvent() {
		this.scrollEvent$ = ObservableFromEvent(this.elm.nativeElement, 'scroll');
	}

	private streamScrollEvents() {
		this.userScrolledDown$ = this.scrollEvent$.pipe(
			map(({ target }: { target: HTMLElement }): ScrollPosition => ({
				sH: target.scrollHeight,
				sT: target.scrollTop,
				cH: target.clientHeight
			})),
			pairwise(),
			filter(positions => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1]))
		);
	}

	private requestCallbackOnScroll() {
		this.requestOnScroll$ = this.userScrolledDown$;

		if (this.immediateCallback) {
			this.requestOnScroll$ = this.requestOnScroll$.pipe(
				startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION])
			);
		}

		this.requestOnScroll$.pipe(
			exhaustMap(() => {
				const res = this.isEnabled && this.scrollCallback();
				this.cd.detectChanges(); // Forced change detection needed: https://stackoverflow.com/a/39787056/2173380
				return res || ObservableOf(res);
			}),
			takeUntil(this.ngUnsubscribe)
		).subscribe(() => { /*console.log('calling');*/ });
	}

	private isUserScrollingDown([oldPos, newPos]: [ScrollPosition, ScrollPosition]) {
		return oldPos.sT < newPos.sT;
	}

	private isScrollExpectedPercent(position: ScrollPosition) {
		return ((position.sT + position.cH) / position.sH) > (this.scrollPercent / 100);
	}

	ngAfterViewInit() {
		this.registerScrollEvent();
		this.streamScrollEvents();
		this.requestCallbackOnScroll();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
